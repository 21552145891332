
/* .ant-modal-header-custom{
    padding-bottom: 0px !important;
} */

.ant-table-summary > tr > td {
    border-bottom: none !important;
}
.reportCol.ant-col.ant-col-24{
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 8px;
}

/* div.ant-modal-header-custom > div.ant-modal-content > div.ant-modal-header {
    padding-bottom: 0px !important;
    padding-top: 15px !important;
    background-color: #F2F3F6 !important;
} */
.tagReport.ant-tag {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    height: 30px;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 28px;
    white-space: nowrap;
    background: white;
    border: 1px solid #D3D3D3;
    border-radius: 2px;
    opacity: 1;
    transition: all 0.3s;
}

